import Main from './pages/Main.js'
import Websites from './pages/Websites.js'
import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom'

function App() {
  return (
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Main />} />
      <Route path='/websites' element={<Websites />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;